/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// React
import { useState } from "react";

// react library for routing
import { Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal
} from "reactstrap";

// Hubspot
import HubspotForm from 'react-hubspot-form'

// core components
import Scene from "components/Scene";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import AuthFooter from "components/Footers/AuthFooter.js";

function Index() {
  // State
  const [contactModal, setContactModal] = useState(false);

  return (
    <>
      <Scene>
        <IndexNavbar />
        <IndexHeader />
      </Scene>
      {/* <section className="py-6 pb-9 bg-default">
        <Container fluid>
          <Row className="justify-content-center text-center">
            <Col md="6">
              <h2 className="heading-font display-2 text-white">
                Meet PERSON AI
              </h2>
              <h3 className="display-4 text-white mt--3 mb-5">
                [ your customer co-pilot ]
              </h3>
              <p className="lead text-white mb-5">
                PersonAI is an AI powered model of your best customers - trained by proprietary data we help you collect.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center text-center">
            <Col md="7">
              <p className="text-white">
                It creates dynamic consumer personas that are constantly being updated with both new data coming into the platform and input from your experts. You can see how your customer's behaviour changes and evolves in real-time and take action before your competition even notices the trend.
              </p>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* <section className="section section-lg pt-lg-0 mt--7">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12">
              <Row>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5 text-center">
                      <h4 className="h3 text-info text-uppercase">
                        Optimize Ad Spend
                      </h4>
                      <p className="description mt-3">
                        Optimize online ad spend through forensic targeting and dynamic segmentation - growing digital return on ad spend (ROAS) by as much as 150% - 225% on average.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5 text-center">
                      <h4 className="h3 text-info text-uppercase">
                        Increase Conversion Rates
                      </h4>
                      <p className="description mt-3">
                        Increase conversion rate, average cart value and customer lifetime values by 2x - 3x on average by offering the right content at the right time to the right customer.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5 text-center">
                      <h4 className="h3 text-info text-uppercase">
                        Reduce Acquisition Costs
                      </h4>
                      <p className="description mt-3">
                        Reduce customer acquisition costs up 50% by identifying high value customers and integrating enriched intent signal back into core marketing tools.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-6">
        <Container>
          <Row className="row-grid align-items-center">
            <Col className="order-md-2" md="6">
              <img
                alt="..."
                className="img-fluid"
                src={require("assets/img/product/platform-1.png").default}
              />
            </Col>
            <Col className="order-md-1" md="6">
              <div className="pr-md-5">
                <h1>A unified definition of personas</h1>
                <p>
                  Provide your organization with a single, unified definition of target personas that live beyond static PDF's and out-of-date marketing presentations.
                </p>
                <ul className="list-unstyled mt-5">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="success">
                          <i className="ni ni-settings-gear-65" />
                        </Badge>
                      </div>
                      <div>
                        <h4 className="mb-0">Dynamically generated persona profiles</h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="success">
                          <i className="ni ni-html5" />
                        </Badge>
                      </div>
                      <div>
                        <h4 className="mb-0">Explain customer behavior in plain english</h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="success">
                          <i className="ni ni-satisfied" />
                        </Badge>
                      </div>
                      <div>
                        <h4 className="mb-0">Enrich existing data and export to external systems</h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-6">
        <Container>
          <Row className="row-grid align-items-center">
            <Col md="6">
              <img
                alt="..."
                className="img-fluid"
                src={require("assets/img/product/platform-2.png").default}
              />
            </Col>
            <Col md="6">
              <div className="pr-md-5">
                <h1>Ask your customers the hard questions</h1>
                <p>
                  Talk with your best customers as if they were in the room. Get feedback on that email or site copy or ask probing questions about why they bought (or didn't).
                </p>
                <ul className="list-unstyled mt-5">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="success">
                          <i className="ni ni-settings-gear-65" />
                        </Badge>
                      </div>
                      <div>
                        <h4 className="mb-0">Fully integrated customer agent dialogue engine</h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="success">
                          <i className="ni ni-html5" />
                        </Badge>
                      </div>
                      <div>
                        <h4 className="mb-0">Start a conversation from a persona, campaign or specific customer action</h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="success">
                          <i className="ni ni-satisfied" />
                        </Badge>
                      </div>
                      <div>
                        <h4 className="mb-0">Incorporate persona dialogue learnings into external tools and assets</h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-7 section-nucleo-icons bg-white">
        <Container>
          <Row className="justify-content-center">
            <Col className="text-center" lg="8">
              <h2 className="display-3">Knowledge-based Visual Perception</h2>
              <p className="lead">
                Based on years of research into neuro-occular response patterns, our cognitive experience engine - <span className="heading-font text-bold" style={{ color: "#4A0C8D" }}>CORTEX</span> - captures and models user behavior signals from a cognitive perspective, utilizing the same biological elements that power human consciousness: Perception and Knowledge.
              </p>
            </Col>
          </Row>
          <div className="blur--hover">
            <a
              href=""
              target="_blank"
              onClick={() => {
                // Trigger success event
                window.gtag('event', 'New_Lead', {
                  'campaign': 'Schedule_Demo'
                });

                // Set modal
                setContactModal(true);
              }}
            >
              <div className="icons-container blur-item mt-5 mb-5">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/product/cortex-visual-engine.png").default}
                />
              </div>
              <span className="blur-hidden h5 text-success">
                Schedule a Demo to Learn More
              </span>
            </a>
          </div>
        </Container>
      </section>
      <section className="py-7">
        <Container>
          <Row className="row-grid justify-content-center">
            <Col className="text-center" lg="8">
              <h2 className="display-3">
                Not your parents analytics tool.
              </h2>
              <p className="lead">
                Analytics tools track past activities as a way to predict future behavior. PERSON AI uses a multitude of data sources (analytics, behavioral, cognitive, 0/1st/3rd party, etc.) to reveal intent and behavior occurring in real time, allowing us to produce deeper insights and more accurate predictions.
              </p>
              <div className="btn-wrapper">
                <Button
                  className="my-2"
                  style={{ color: "#FFFFFF", backgroundColor: "#4433BB", minWidth: isMobile ? 300 : 400 }}
                  onClick={() => {
                    // Trigger success event
                    window.gtag('event', 'New_Lead', {
                      'campaign': 'Schedule_Demo'
                    });

                    // Set modal
                    setContactModal(true);
                  }}
                >
                  Schedule a Demo to Lean More
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* <AuthFooter /> */}
      <Modal
        className="modal-dialog-centered"
        isOpen={contactModal}
        toggle={() => setContactModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Schedule a Demo
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setContactModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <HubspotForm
           portalId='48101296'
           formId='0353aa3b-37b3-4b8a-939a-076774df5981'
           onSubmit={() => console.log('Submit!')}
           onReady={(form) => console.log('Form ready!')}
           loading={<div>Loading...</div>}
           />
        </div>
      </Modal>
    </>
  );
}

export default Index;