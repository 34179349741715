/*
 *  XR-Scene
 */

/*eslint-disable*/

// React
import { useState } from "react";
import { isMobile } from 'react-device-detect';

// Animation
import { useTransition, animated as a } from "react-spring";

// Imports
import IframeComm from "react-iframe-comm";

// Account config
import config from "config";

function Scene({ message, children }) {
  // State
  const [loading, setLoading] = useState(true);
  const [heroCTA, setHeroCTA] = useState(false);

  // Loading
  const transitions = useTransition(loading, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 3000 }
  });

  // iFrame attributes
  const attributes = {
      //src: "http://localhost:1234",
      src: isMobile ? "https://storage.googleapis.com/avant-garde-cdn/cognetix/v6/index.mobile.html" : "https://storage.googleapis.com/avant-garde-cdn/cognetix/v6/index.html",
      width: "100%",
      height: "100%",
      allow: "camera *;microphone *"
  };

  // parent received a message from iframe
  const onReceiveMessage = (msg) => {
    if(msg && msg.isTrusted && msg.data) {
      // Parse message
      const parsedMessage = (typeof msg.data === 'string') ? JSON.parse(msg.data) : msg.data;
      console.log("[XR-Scene] Received Message from XR Scene:", parsedMessage);

      // Perform action
      if(typeof parsedMessage.action === 'string') {
        switch(parsedMessage.action) {
          case 'xr:scene:init':
            if(parsedMessage.data) {
                console.log("Initializing Scene with config: ", parsedMessage.data);
                //this.props.dispatch('explorer/scene/init', parsedMessage.data);
            }
            break;
          case 'xr:scene:click':
            //this.props.dispatch('explorer/scene/open');
            console.log("Scene click!");
            break;
          default:
            console.log("ERROR: No corresponding action for " + parsedMessage.action);
        }
      }
    }
  };

  // iframe has loaded
  const onReady = () => {
      console.log("[XR-Scene] XR Scene Ready");

      // Set timer for message
      setTimeout(function() {
        // Show hero message
        setHeroCTA(true);
      }, 2000)
  };

  return (
    <>
    <section className="hero" style={{
      width: '100vw',
      height: '100vh',
      backgroundColor: '#020511',
      // backgroundImage: `url(${require("assets/img/brand/logo.app.full.png").default})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed'
    }}>
      { transitions((style, i) => (
        <a.div className="scene-player" style={{
          ...style,
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
        }}>
          <IframeComm
              attributes={attributes}
              //postMessageData={message}
              handleReady={onReady}
              //handleReceiveMessage={onReceiveMessage}
          />
          { heroCTA &&
            <div className="scene-content">
              { children }
            </div>
          }
        </a.div>
      ))}
    </section>
    </>
  );
}

export default Scene;


/*

<div className="scene-content">
  { children }
</div>


{ loading ?
  <section className="hero" style={{
    width: '100vw',
    height: '100vh',
    backgroundColor: '#1E3856',
    backgroundImage: `url(${require("assets/img/brand/logo.app.png").default})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed'
  }}></section>
  :
  <section className="hero" style={{
    width: '100vw',
    height: '100vh',
    backgroundColor: '#1E3856',
    //backgroundImage: `url(${require("assets/img/brand/background.png").default})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed'
  }}>
    <div className="scene-player" style={{
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      top: 0,
      left: 0
    }}>
      <IframeComm
          attributes={attributes}
          //postMessageData={message}
          handleReady={onReady}
          //handleReceiveMessage={onReceiveMessage}
      />
    </div>
    <div className="scene-content">
      { children }
    </div>
  </section>
}

*/
