/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React
import { useState } from "react";

// Animation
import { useTransition, animated as a } from "react-spring";

// react library for routing
import { Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';

// reactstrap components
import { Button, Card, CardBody, Container,
         Modal, Form, FormGroup, Row, Col
} from "reactstrap";

// Hubspot
import HubspotForm from 'react-hubspot-form'

function IndexHeader() {
  // State
  const [loading,gitLoading] = useState(true);
  const [contactModal, setContactModal] = useState(false);
  const [portfolioModal, setPortfolioModal] = useState(false);

  // Loading
  const transitions = useTransition(loading, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 1000 }
  });

  // Create container styles
  const ctaStyles = isMobile ? {
    position: 'absolute',
    bottom: 50,
    left: 20,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  } : {
    position: 'absolute',
    bottom: 50,
    left: 50
  };

  const handleLinkedInClick = () => {
    const linkedInUrl = 'https://www.linkedin.com/in/garretteastham/';
    const linkedInAppUrl = 'linkedin://profile/garretteastham';
    
    // Check if user is on a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    if (isMobile) {
      // Try to open LinkedIn app first
      const tryDeepLink = () => {
        const fallback = () => {
          // If deep link fails, open in browser
          window.location.href = linkedInUrl;
        };

        // Create an iframe to attempt the deep link
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        // Set timeout for fallback
        setTimeout(() => {
          document.body.removeChild(iframe);
          fallback();
        }, 2000);

        // Attempt deep link
        iframe.src = linkedInAppUrl;
      };

      tryDeepLink();
    } else {
      // On desktop, simply open in new tab
      window.open(linkedInUrl, '_blank');
    }
  };

  return (
    <>
      { transitions((style, i) => (
        <a.div style={style}>
          <Container style={ctaStyles}>
            <div className="header-body">
              <Row className="align-items-center">
                <Col xl="6">
                  <div className="pr-5">
                    <h1 className={`heading-font display-2 text-white font-weight-bold mb-2 ${isMobile ? ' text-center' : ''}`}>
                      Intelligence. Delivered.
                    </h1>
                    <h2 style={isMobile ? { fontSize: '110%' } : {}} className={`display-5 text-white font-weight-light ${isMobile ? ' text-center' : ''}`}>
                    We help SaaS platforms integrate modern AI capabilities while leveraging their existing customer relationships and data.
                    </h2>
                    <div className={`mt-4${isMobile ? ' text-center' : ''}`}>
                      <Button
                        className="my-2"
                        style={{ color: "#FFFFFF", backgroundColor: "#5F93BC", minWidth: isMobile ? 120 : 180 }}
                        onClick={handleLinkedInClick}
                      >
                        Learn More
                      </Button>
                      <Button
                        className="my-2"
                        style={{ color: "#FFFFFF", backgroundColor: "#4433BB", minWidth: isMobile ? 100 : 180 }}
                        onClick={() => {
                          // Trigger success event
                          window.gtag('event', 'New_Lead', {
                            'campaign': 'Schedule_Demo'
                          });

                          // Set modal
                          setContactModal(true);
                        }}
                      >
                        Schedule a Call
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col xl="6">

                </Col>
              </Row>
            </div>
          </Container>
          <Modal
            className="modal-dialog-centered"
            isOpen={contactModal}
            toggle={() => setContactModal(false)}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                Contact Us
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setContactModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <HubspotForm
               portalId='42664088'
               formId='578a5f95-6a58-49a5-a18a-fb8dfdea1cfb'
               onSubmit={() => console.log('Submit!')}
               onReady={(form) => console.log('Form ready!')}
               loading={<div>Loading...</div>}
               />
            </div>
          </Modal>
        </a.div>
      ))}
    </>
  );
}

export default IndexHeader;
