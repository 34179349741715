/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React
import { useState } from "react";

// Animation
import { useTransition, animated as a } from "react-spring";

// react library for routing
import { Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';

// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
} from "reactstrap";

function AdminNavbar() {
  // State
  const [loading, setLoading] = useState(true);

  // Loading
  const transitions = useTransition(loading, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 1000 }
  });

  return (
    <>
      { transitions((style, i) => (
        <a.div style={style}>
          <Navbar
            className="navbar-horizontal navbar-main navbar-dark pt-4"
            expand="lg"
            id="navbar-main"
            style={{
              backgroundColor: '#020511',
              zIndex: 1000000,
              position: 'fixed',
              top: 0,
              width: '100%'
            }}
          >
            <Container style={{
              marginLeft: 0,
              paddingTop: 10,
              paddingBottom: 10
            }}>
              <NavbarBrand
                to="/"
                tag={Link}
                style={(isMobile) ?
                  {
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }
                  :
                  {}
                }
              >
                <img
                  alt="..."
                  src={require("assets/img/brand/logo-white.svg").default}
                  style={(isMobile) ?
                    {
                      marginLeft: 0,
                      minHeight: 60,
                      maxHeight: 150
                    }
                    :
                    {
                      marginLeft: 20,
                      minHeight: 100,
                      maxHeight: 200
                    }
                  }
                />
              </NavbarBrand>
            </Container>
          </Navbar>
        </a.div>
      ))}
    </>
  );
}

export default AdminNavbar;


/*

<Navbar
  className="navbar-horizontal navbar-main navbar-dark pt-4"
  expand="lg"
  id="navbar-main"
  style={{
    backgroundColor: '#1E3856'
  }}
>
  <Container>
    <NavbarBrand to="/" tag={Link}>
      <img
        alt="..."
        src={require("assets/img/brand/logo-white.svg").default}
        style={{
          minHeight: 50
        }}
      />
    </NavbarBrand>
    <button
      aria-controls="navbar-collapse"
      aria-expanded={false}
      aria-label="Toggle navigation"
      className="navbar-toggler"
      data-target="#navbar-collapse"
      data-toggle="collapse"
      id="navbar-collapse"
      type="button"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <UncontrolledCollapse
      className="navbar-custom-collapse"
      navbar
      toggler="#navbar-collapse"
    >
      <div className="navbar-collapse-header">
        <Row>
          <Col className="collapse-brand" xs="6">
            <Link to="/admin/dashboard">
              <img
                alt="..."
                src={require("assets/img/brand/logo.svg").default}
              />
            </Link>
          </Col>
          <Col className="collapse-close" xs="6">
            <button
              aria-controls="navbar-collapse"
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navbar-collapse"
              data-toggle="collapse"
              id="navbar-collapse"
              type="button"
            >
              <span />
              <span />
            </button>
          </Col>
        </Row>
      </div>
    </UncontrolledCollapse>
  </Container>
</Navbar>


*/
